.farming-container {
  background-color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  overflow: scroll;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: black;
}

.farming-user-name {
  background-color: rgb(205, 235, 69);
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: large;
  color: black;
}

.farming-checkout-task-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px;
}

.farming-checkout-task {
  color: white;
  font-weight: bold;
  border-left: 5px;
  border-left-color: #cdeb45;
  border-left-style: solid;
  padding: 10px;
}

.farming-checkout-icon {
  font-size: x-large;
  color: #cdeb45;
}

.farming-user-icon {
  margin-top: 40px;
  margin-bottom: 20px;
  color: #046787;
  display: inline-block;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-size: cover;
  font-family: "Casino";
  font-size: 68px;
  text-align: center;
  line-height: 150px;
}

.farming-.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%;
  border: 5px solid white; /* Thick white border */
  background-size: cover;
  background-position: center;
  font-size: 24px; /* Adjust font size for initials */
  font-weight: bold;
  color: white;
  text-transform: uppercase; /* Ensure initials are uppercase */
  position: relative;
  overflow: hidden;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  color: #745e50;
}

.farming-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.forming-container {
  margin-bottom: 5px;
}

.forming-avator {
  width: 200px;
  height: 200px;
}

.reward-text,
.bonus-text {
  text-align: center;
  margin: 5px 0;
  color: white;
  margin-bottom: "20px";
}

.bonus-text {
  font-size: 0.9em;
  color: #a67c52;
}

.friends-container {
  width: 100%;
}

.friends-container h2 {
  font-size: 16px;
  color: #cdeb45;
  margin-bottom: 10px;
}

.farming-join-community {
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px solid #fff;
  background-color: transparent;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
}

.farming-balance-display {
  color: #cdeb45;
  font-size: 56px;
  font-family: "Casino";
}

.friend-avatar {
  width: 50px;
  height: 50px;
  background-color: #535151;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: white;
}
