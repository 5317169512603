.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.user-email-container {
  background-color: white;
  border-radius: 20px;
  padding: 26px 16px;
  width: 82%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-email-description {
  width: 92%;
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
  font-family: 'myriadpro-bold';
}

.user-email-title {
  font-family: "Casino";
  font-size: 36px;
  color: #bc4527;
  margin: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
}

.user-email-card-icon {
  font-size: 48px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.user-email-submit-button {
  background-color: transparent;
  color: #bc4527;
  font-weight: bold;
  font-size: 18px;
}

h3 {
  margin: 10px 0;
  font-size: 32px;
  font-weight: 300;
  color: black;
}

p {
  color: black;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
}

button {
  color: #fa5949;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

button.submit-button {
  margin-top: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
}

button.submit-button.disabled {
  color: black;
  background-color: #cdeb45;
  border: none;
}

.user-email-input {
  background-color: #d9d9d9;
  width: 70%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  text-align: center;
  color: #000;
  font-family: 'myriadpro-semibold';
}

.user-email-input:focus {
  outline: 2px solid #bc4527;
}
