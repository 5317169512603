.mining-cards-container {
  padding: 20px;
}

.category-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.category-tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
}

.category-tab:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.category-tab:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #B6784F;
  transition: all 0.3s ease;
  z-index: 0;
  border-radius: 10px;
}

.category-tab.active {
  color: white;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}