.step-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 10px;
  /* Positioning at the bottom of the screen */
  left: 0;
  right: 0;
  padding: 10px 20px;
  background-color: transparent;
}

.step-navigation > a {
  padding: 0;
}

a {
  text-decoration: none;
  padding: 8px 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.step-counter {
  font-size: 18px;
  font-weight: bold;
  color: white;
}
