.onboarding-container-black {
  background-color: black;
  height: 85vh;
  display: flex;
  flex-direction: column;
}

.onboarding-container-white {
  background-color: white;
  height: 85vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: black;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  color: #745e50;
}

.user-friend-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.image-container {
  margin-bottom: 20px;
}

.loading-icon {
  width: 256px;
  height: 256px;
}

.primary-text-white {
  text-align: center;
  margin: 20px 0;
  font-size: xx-large;
  color: white;
  font-weight: 700;
}

.primary-text-black {
  text-align: center;
  margin: 20px 0;
  font-size: xx-large;
  color: black;
}

.secondry-text-white {
  text-align: center;
  margin: 20px 20px;
  color: white;
  font-size: 14px;
}

.secondry-text-black {
  text-align: center;
  margin: 20px 20px;
  color: black;
}

.bonus-token {
  color: #ffbf4b;
  text-align: "center";
  font-weight: bolder;
  font-size: 40px;
  margin-top: 0px;
}

.friends-container {
  width: 100%;
}

.friends-container h2 {
  font-size: 16px;
  color: #cdeb45;
  margin-bottom: 10px;
}

.friend-name {
  flex-grow: 1;
  color: #262524;
}

.friend-tokens {
  color: #262524;
  font-weight: bold;
}

.invite-button {
  height: 30px;
  background-color: #cdeb45;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

/* .invite-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
  width:100%;
} */

.invite-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
  /*   width:90%; */
  position: fixed; /* Fixed position relative to the viewport */
  bottom: 50px; /* Dock to the bottom of the viewport */
  z-index: 100; /* Ensure it's above other elements */
}

.invite-button {
  height: 30px;
  background-color: #cdeb45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  animation: pulseButton 1s infinite ease-in-out;
}

.countdown {
  text-align: center;
  margin-bottom: 4px;
  color: white;
  font-size: larger;
  color: #cdeb45;
}

.copy-button {
  background-color: #cdeb45;
  border: none;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.copy-button svg {
  width: 24px;
  height: 24px;
  color: white;
}

.invite-button:hover,
.copy-button:hover {
  opacity: 0.9;
}

.invite-button:active,
.copy-button:active {
  opacity: 0.8;
}

@keyframes pulseButton {
  0% {
    width: 200px;
  }
  50% {
    width: 205px;
  }
  100% {
    width: 200px;
  }
}

.animated-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  animation: pulseButton 1s infinite ease-in-out;
}
