.left-top{
    position: absolute;
  top: 16px;
  left: 4px;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
 
}