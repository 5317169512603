.landing-container-black {
  background-color: black;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Full viewport height to center vertically */
}

.landing-container {
  width: 100%;
}

.landing-content {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.home-image-container {
  margin-bottom: 50px;
}

.loading-animation {
  height: 256px;
  width: 256px;
}

.primary-text-white {
  text-align: center;
  margin: 20px 0;
  font-size: xx-large;
  color: white;
}

.secondry-text-white {
  text-align: center;
  margin: 20px 20px;
  color: white;
}
