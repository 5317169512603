.invite-friend-container {
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: black;
}

.invite-heading {
  font-size: 30px;
  font-weight: bold;
  color: rgb(205, 235, 69);
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  color: #745e50;
}

.invite-friend-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
}

.buddied-image-container {
  margin-bottom: 5px;
}

.friend-cute-character {
  width: 160px;
  height: 160px;
  object-fit: contain;
}

.friends-reward-text {
  text-align: center;
  margin: 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.earn-more-text {
  font-size: 24px;
  margin-top: 4px;
  text-align: center;
  margin-bottom: 0px;
  color: rgb(255, 210, 77);
}

.bonus-text {
  font-size: 0.9em;
  color: #a67c52;
}

.inviteBtn {
  height: 56px;
  flex: 0 0 78%;
  font-size: 16px;
  animation: animateBtn1 1s ease-in-out infinite;
  transform-origin: center;
  border-radius: 10px;
  border: none;
  background-color: #cdeb45;
  color: black;
  font-weight: bold;
  margin: 0;
}

.copyBtn {
  width: 56px; /* Set width instead of flex */
  height: 56px;
  border-radius: 10px;
  border: none;
  background-color: #cdeb45;
  color: black;
  font-weight: bold;
  margin: 0;
}

.toggle-container {
  gap: 16px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.toggle-item {
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
  color: gray;
  cursor: pointer;
}

.toggle-item.active {
  color: #cdeb45;
}

.friends-tab {
  width: 100%;
}

.friends-tab h2 {
  font-size: 16px;
  color: #cdeb45;
  margin-bottom: 10px;
}

.invite-friend-list {
  list-style: none;
  padding: 0;
  padding-bottom: 80px;
  /* max-height: 260px; */
  /* Set a fixed height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  margin-bottom: 0px;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}

.invite-friend-item {
  display: flex;
  align-items: center;
  background-color: #5b5b5b;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  padding: 5px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.buddy-avatar {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: white;
}

.friend-list-name {
  flex-grow: 1;
  color: #262524;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.friend-list-name > span {
  font-size: 16px;
}

.divider {
  height: 16px;
  width: 1px;
  background-color: black;
}

.invite-friend-tokens {
  gap: 4px;
  display: flex;
  color: #262524;
  font-weight: bold;
  align-items: center;
}

.invite-button {
  height: 30px;
  background-color: #cdeb45;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

/* .invite-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
  width:100%;
} */

.invite-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
  width: 90%;
  position: fixed;
  /* Fixed position relative to the viewport */
  bottom: 0px;
  /* Dock to the bottom of the viewport */
  z-index: 100;
  /* Ensure it's above other elements */
}

.invite-button {
  height: 30px;
  background-color: #cdeb45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  animation: pulseButton 1s infinite ease-in-out;
}

.countdown {
  text-align: center;
  margin-bottom: 4px;
  color: white;
  font-size: larger;
  color: #cdeb45;
}

.copy-button {
  background-color: #cdeb45;
  border: none;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.copy-button svg {
  width: 24px;
  height: 24px;
  color: white;
}

.invite-button:hover,
.copy-button:hover {
  opacity: 0.9;
}

.invite-button:active,
.copy-button:active {
  opacity: 0.8;
}

@keyframes pulseButton {
  0% {
    width: 200px;
    font-size: 16px;
  }

  50% {
    width: 205px;
    font-size: 18px;
  }

  100% {
    width: 200px;
    font-size: 16px;
  }
}

.animated-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  animation: pulseButton 1s infinite ease-in-out;
}

.invite-buddy-button {
  height: 50px;
  font-size: 16px;
  background-color: #cdeb45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  animation: inviteButton 1s infinite ease-in-out;
}

@keyframes inviteButton {
  0% {
    width: 100px;
    font-size: 16px;
  }

  50% {
    width: 105px;
    font-size: 17px;
  }

  100% {
    width: 100px;
    font-size: 16px;
  }
}

.invite-btn-container {
  width: 86%; /* Cover all available width of the parent container */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between the buttons */
  bottom: 115px;
  position: fixed;
}

.btn1 {
  flex: 0 0 75%; /* Initial flex-basis of 60% */
  font-size: 16px; /* Initial font size */
  animation: animateBtn1 1s ease-in-out infinite;
  transform-origin: center; /* Set the origin to center for scaling */
  border-radius: 10px;
  border: none;
  background-color: #cdeb45;
  color: black;
  font-weight: bold;
}

.btn2 {
  width: 20%; /* Set width instead of flex */
  border-radius: 10px;
  border: none;
  background-color: #cdeb45;
  color: black;
  font-weight: bold;
  padding: 15px;
}

@keyframes animateBtn1 {
  0% {
    transform: scaleX(1); /* No scaling, default width */
    font-size: 14px; /* Initial font size */
  }
  50% {
    transform: scaleX(1.05); /* Expand horizontally by 20% */
    font-size: 16px; /* Increase font size */
  }
  100% {
    transform: scaleX(1); /* Reset to original width */
    font-size: 14px; /* Reset font size */
  }
}
