/* General container styling */
.casino-container {
  background-color: black;
  height: 78vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  color: white;
}

/* Header styling */
.casino-title {
  font-size: 50px;
  font-weight: bold;
  color: #cdeb45;
  text-align: center;
}

.casino-subtitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 20px;
}

.casino-subtitle-green {
  font-size: 16px;
  font-weight: bold;
  color: #cdeb45;
  text-align: center;
  margin-top: 20px;
}

/* Timer styling */
.casino-timer {
  font-size: 48px;
  font-weight: bold;
}

/* Image styling */
.casino-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.casino-image {
  width: 256px;
  height: 256px;
}

/* Content and button styling */
.casino-content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.go-button {
  width: 70px;
  height: 70px;
  color: black;
  background-color: #cdeb45;
  font-weight: bold;
  font-size: 32px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  line-height: 76px;
}

.casino-thanks {
  width: 80%;
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
  font-family: "myriadpro-semibold";
}
