/* Container for the navbar */
:root {
  --clr: #000;
  --screen-bg-clr: #cdeb45;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navigation {
  position: fixed; /* Stay fixed at the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: #4e4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it stays on top of other content */
}
.navigation ul {
  display: flex;
  width: 350px;
}
.navigation ul li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
}
.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: var(--clr);
  opacity: 0.36;
}
.navigation ul li.active a .icon {
  transform: translateY(-34px);
  opacity: 1;
}
.icon svg path {
  fill: white;
  opacity: 1;
}
.navigation ul li a .text {
  position: absolute;
  color: var(--clr);
  font-weight: 400;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}
.navigation ul li.active a .text {
  opacity: 1;
  transform: translateY(2px);
  color: #fff;
}
.indicator {
  position: absolute;
  top: -46%;
  width: 70px;
  height: 70px;
  background: var(--screen-bg-clr);
  border-radius: 50%;
  border: 6px solid var(--clr);
  transition: 0.5s;
}
.indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  width: 30px;
  height: 23px;
  background: transparent;
  border-top-right-radius: 100%;
  box-shadow: 1px -10px 0 0 var(--clr);
}
.indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  width: 30px;
  height: 26px;
  background: transparent;
  border-top-left-radius: 100%;
  box-shadow: -1px -10px 0 0 var(--clr);
}

/* Indicator Transitions */
.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(70px * 0));
}
.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(70px * 1));
}
.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(70px * 2));
}
.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(70px * 3));
}
.navigation ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(70px * 4));
}
.navigation ul li:nth-child(6).active ~ .indicator {
  transform: translateX(calc(70px * 4));
}
