.chef-slider {
  display: flex;
  flex-direction: column;
  height: 86vh;
  color: #333;
}

header {
  padding: 20px;
  background-color: #e0c090;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
}

.chef-image {
  max-width: 90%;
  max-height: 36vh;
  object-fit: contain;
  margin: 20px 0;
}

.progress-bar {
  width: 80%;
  height: 10px;
  background-color: #754020;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  border: 2px solid #754020;
}

.progress {
  height: 100%;
  background-color: #ffd700;
  transition: width 0.3s ease;
  border-radius: 10px;
}

.user-level-coins {
  font-weight: 400;
  font-size: 24px;
  color: white;
}

nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.nav-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

footer {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #e0c090;
}

.footer-button {
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.nav-button.next {
  position: absolute;
  right: 0;
  font-size: 64px;
}
.nav-button.prev {
  position: absolute;
  left: 0;
  font-size: 64px;
}
