body {
  background-color: black;
}
#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

@font-face {
  font-family: "Casino";
  src: local("Casino"),
    url("./fonts/static/Casino.ttf") format("truetype");
}

/* @font-face {
  font-family: "outfit-regular";
  src: local("outfit-regular"),
    url("./fonts/static/outfit-regular.ttf") format("truetype");
}

@font-face {
  font-family: "outfit-semi-bold";
  src: local("outfit-semi-bold"),
    url("./fonts/static/outfit-semibold.ttf") format("truetype");
}

@font-face {
  font-family: "outfit-bold";
  src: local("outfit-bold"),
    url("./fonts/static/outfit-bold.ttf") format("truetype");
} */

@font-face {
  font-family: "myriadpro-regular";
  src: local("Myriadpro-regular"),
    url("./fonts/static/myriadpro-regular.ttf") format("truetype");
}

@font-face {
  font-family: "myriadpro-semibold";
  src: local("Myriadpro-semibold"),
    url("./fonts/static/myriadpro-semibold.ttf") format("truetype");
}

@font-face {
  font-family: "myriadpro-bold";
  src: local("Myriadpro-bold"),
    url("./fonts/static/myriadpro-bold.ttf") format("truetype");
}

* {
  font-family: "myriadpro-regular";
}

.App {
  height: 100vh;
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  /* padding-bottom: 80px; Adjust this value based on the height of your navbar */
  background-color: black;
  overflow: hidden;
}

#game-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

#phaser-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  height: calc(100% - 80px);
  overflow: hidden;
  overflow-y: scroll;
}

.content {
  padding: 20px;
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #5e688b;
  z-index: 5;
  width: 80%;
  max-width: 400px;
  text-align: center;
  border-radius: 10px;
}

.content p {
  margin: 0;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.menu-icon {
  font-size: 24px;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 44px;
  height: 44px;

  border-radius: 10px;
  background-color: #fff0;
  border: 1px solid #745e5033;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.stat-box {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  flex: 1;
  margin: 0 5px;
}

.stat-box-header {
  font-size: 12px;
  font-weight: 600;
  line-height: 15.12px;
  text-align: center;
  color: #b6784f;
}
.stat-box-content {
  font-size: 14px;
  font-weight: 600;

  text-align: left;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Added to position energy relative to the coins */
}
.stat-box-content span {
  margin: 0 5px;
}

.profile {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333;
}

.daily-tasks {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.task {
  text-align: center;
  background-color: #333;
  padding: 10px;
  border-radius: 10px;
}

.coins {
  font-size: 40px;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Added to position energy relative to the coins */
  font-weight: 600;
}
.coins-text {
  /* min-width: 200px; */
  text-align: center;
  margin-left: 5px;
  color: white;
}

.energy {
  margin: 20px auto;
  font-size: 18px;
  background: #fff;
  color: #000;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 300px;
  text-align: center;
}
.tile-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 300px;
  margin: 20px auto;
}

.tile-button > div {
  background: #fff;
  color: #000;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  position: relative;
}

.tile-button > div span {
  font-weight: bold;
  color: #404f68;
}

.level-indicator {
  position: absolute;
  top: -4px;
  right: 4px;
  background-color: #ff9800; /* Orange background, you can change this */
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.footer-item {
  text-align: center;
}

.booseter-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
.booster-btn {
  width: 70px;
  height: 44px;

  border-radius: 10px;
  background-color: #fff0;
  border: 1px solid #745e5033;
  margin-right: 10px;
}
