.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.confirmation {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: black;
}

.confirmation button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button {
  background-color: #cdeb45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  width: 100%;
}

.buy-button {
  background-color: #f6c427;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
}

.confirmation button.confirm {
  background-color: #28a745;
  color: white;
}

.confirmation button.cancel {
  background-color: #dc3545;
  color: white;
}
/* FoodItem.css */
.earn-more-item {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  width: 82%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  height: auto;
}

.red-text {
  font-size: 24px;
  color: #cf0e00;
  line-height: 38px;
  vertical-align: text-bottom;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}

h3 {
  margin: 10px 0;
  font-size: 18px;
}

p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.ratings {
  font-size: 16px;
  color: #ffd700;
  margin-bottom: 5px;
}

.point {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.earnmore-list {
  list-style: none;
  padding: 0;
  max-height: 400px; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.earnmore-item {
  display: flex;
  align-items: center;
  background-color: #292a2b;
  color: white;
  border-radius: 8px;
  padding: 5px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.earnmore-avatar {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: white;
  vertical-align: text-bottom;
}

.earnmore-name {
  flex-grow: 1;
  color: #cdeb45;
  text-align: left;
}
.green-text {
  color: #cdeb45;
  font-weight: bold;
  font-size: 18px;
  padding-left: 6px;
}
/* .earnmore-icon {
  font-size: 14px;
} */

.earnmore-icon {
  margin-top: 20px !important;
}

.earnmore-description {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 18px;
}
