.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.confirmation {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: black;
}

.confirmation button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* 
.confirm-button {
  background-color: #CDEB45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin:0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  width: 100%;
  
}
 */

.buy-button {
  background-color: #f6c427;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
}

.confirmation button.confirm {
  background-color: #28a745;
  color: white;
}

.confirmation button.cancel {
  background-color: #dc3545;
  color: white;
}
/* FoodItem.css */
.confirm-item {
  background-color: white;
  min-height: 320px;
  border-radius: 15px;
  padding: 20px;
  width: 250px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}

.card-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

h3 {
  margin: 10px 0;
  font-size: 18px;
}

p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.ratings {
  font-size: 16px;
  color: #ffd700;
  margin-bottom: 5px;
}

.point {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.confirm-continue-button {
  padding: 0px;
  color: black;
}

.confirm-yes-button {
  font-size: 16px;
  background-color: #cdeb45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  flex-grow: 1;
  text-align: center;
  width: 100px;
  animation: confirmYesButton 1s infinite ease-in-out;
}

@keyframes confirmYesButton {
  0% {
    width: 100px;
    font-size: 16px;
  }
  50% {
    width: 105px;
    font-size: 17px;
  }

  100% {
    width: 100px;
    font-size: 16px;
  }
}
