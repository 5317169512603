.button-3d {
  touch-action: manipulation;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  padding: 1.25em 0;
  width: 150px;
  background: #cceb44;
  box-shadow: 0 0.6em 0 0 #8fa62a;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1),
    box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

.button-3d::before {
  position: absolute;
  content: "";
  width: 116%;
  height: 106%;
  top: 0;
  left: 50%;
  background: #fff;
  border-radius: inherit;
  box-shadow: 0 0.625em 0 0 #4e4e4e;
  transform: translateX(-50%) translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

.button-3d:active {
  background: #cceb44;
  transform: translate(0, 0.75em);
  box-shadow: none;
}

.button-3d:active::before {
  transform: translateX(-50%) translate3d(0, 0, -1em);
}

.button-3d .text {
  position: relative;
  z-index: 1;
  font-family: "myriadpro-bold";
}

.button-3d.gray {
  background: #4b4b4b;
  box-shadow: 0 0.6em 0 0 #292929;
  transition: none;
}

.button-3d.disabled {
  background: #4b4b4b;
  box-shadow: 0 0.6em 0 0 #292929;
  color: #fff;
  /* transform: translate(0, 0.75em); */
}

.button-3d.gray {
  background: #4b4b4b;
  box-shadow: 0 0.6em 0 0 #292929;
  color: #fff;
  /* transition: none !important; */
  transition: background 150ms cubic-bezier(0, 0, 0.58, 1),
    box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

.button-3d.gray::before {
  transform: translateX(-50%) translate3d(0, 0.75em, -1em) !important;
  transition: none !important;
}

.button-3d.gray:active {
  transform: none !important;
  box-shadow: 0 0.6em 0 0 #292929 !important;
}
